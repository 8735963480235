import { ArbitraryObject } from '@/utils/interfaces';
import {
  ContractType,
  Country,
  DocumentProps,
  EstimationStage,
  LeadSource,
  ListingStatus,
  MandateChargedToType,
  MandateType,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  PropertyEnum
} from '@proprioo/hokkaido';
import { TagColorsTypes } from '@proprioo/salatim';

import { LocationState } from '../buyerQualification/interfaces';
import {
  OpportunityQualification,
  SellerOwnership
} from '../qualification/sellerQualification/interfaces';

export enum Lazyload {
  ON_DEMAND = 'ondemand',
  PROGRESSIVE = 'progressive'
}

export enum ListingCoolingType {
  CENTRAL = 'central',
  INDEPENDENT = 'independent',
  NONE = 'none',
  READY = 'ready'
}

export enum ListingDistribution {
  BELLES_DEMEURES = 'belles-demeures',
  BIEN_ICI = 'bien-ici',
  FIGARO_IMMO = 'explor-immo',
  FIGARO_PROPERTIES = 'immovision-v2',
  LEBONCOIN = 'ubiflow',
  LISTGLOBALLY = 'listglobally',
  NICE_MATIN = 'nice-matin',
  OUEST_FRANCE = 'ouest-france',
  OTHERS = 'others',
  PARU_VENDU = 'paruvendu',
  SE_LOGER = 'seloger',
  VAR_MATIN = 'var-matin',
  JAMESLIST_IMMO = 'james'
}

export enum ListingEnergyDiagnosticState {
  RECENT = 'Récent',
  TO_BE_DONE = 'À faire'
}

export enum ListingEnergyRating {
  A4 = 'a4',
  A3 = 'a3',
  A2 = 'a2',
  A1 = 'a1',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  PENDING = 'pending'
}

export enum ListingFeesRecurrenceType {
  UNDEFINED,
  MONTHLY,
  QUARTERLY,
  ANNUAL
}

export enum ListingFrontType {
  UNDEFINED,
  CUT_STONE,
  BRICK,
  CONCRETE,
  COAT,
  CLASSIC,
  NOT_MENTIONED,
  STAPLED_STONE,
  GRINDING_STONE
}

export enum ListingHeatingLocation {
  UNDEFINED = '0',
  UNDERFLOOR = 'Au sol',
  WALL = 'Mural'
}

export enum ListingHeatingMode {
  UNDEFINED = '0',
  ELECTRIC = 'Electric',
  GAS = 'Gaz',
  FUEL = 'Fuel',
  WOOD = 'Wood',
  HEAT_PUMP = 'HeatPump',
  PELLETS = 'Granulés',
  SOLAR = 'Solaire',
  CPCU = 'CPCU',
  GEOTHERMAL = 'Geothermal'
}

export enum ListingHeatingType {
  UNDEFINED,
  COMMUNAL,
  INDIVIDUAL
}

export enum ListingHeatingUnderfloorType {
  UNDEFINED = '0',
  LOW_TEMPERATURE = 'Basse température',
  HIGH_TEMPERATURE = 'Haute température'
}

export enum ListingLivingRoomViewType {
  UNDEFINED = '0',
  STREET = 'Rue',
  COURTYARD = 'Cour',
  SEA = 'Mer',
  PARK = 'Parc',
  GARDEN = 'Jardin'
}

export enum ListingOnSaleType {
  UNDEFINED = '0',
  PRIVATE = '1',
  AGENCY = '2',
  NOTARY = '3',
  OTHER = '4'
}

export enum LightshopOrderStatus {
  CANCELLED = 'cancelled',
  DELIVERED = 'delivered',
  EXPIRED = 'expired',
  PLANNED = 'planned'
}

export enum ListingParkingJointType {
  UNDEFINED = '0',
  ONE_SIDE = '1 côté',
  TWO_SIDES = '2 côté'
}

export enum ListingParkingType {
  UNDEFINED = '0',
  INSIDE = 'Intérieur',
  OUTSIDE = 'Exterieur'
}

export enum ListingQuality {
  NEED,
  REFRESH,
  CORRECT,
  NEW,
  REFURBISHED,
  UNDER_CONSTRUCTION
}

export enum ListingWindowType {
  UNDEFINED,
  SINGLE_GLAZING,
  DOUBLE_GLAZING,
  TRIPLE_GLAZING
}

export enum PublicationCheckErrorKind {
  MISSING_BATHROOM = 'MissingBathRooms',
  MISSING_BEDROOM = 'MissingBedrooms',
  MISSING_DPE = 'MissingDPE',
  MISSING_ENERGY_CONSUMPTION = 'MissingEnergyConsumption',
  MISSING_ENERGY_RATING = 'MissingEnergyRating',
  MISSING_FLOOR_PLANS = 'MissingFloorPlans',
  MISSING_GEOLOCATION = 'MissingGeolocation',
  MISSING_GES = 'MissingGES',
  MISSING_MANDATE = 'MissingMandate',
  MISSING_MANDATE_SIGNATURE = 'MandateNotSigned',
  MISSING_MINIMUM_PICTURES = 'MissingPictures',
  MISSING_PRICE = 'MissingPrice',
  MISSING_PROPERTY_EXPOSURE = 'MissingPropertyExposure',
  MISSING_RENOVATION_STATUS = 'MissingRenovationStatus',
  MISSING_ROOM = 'MissingRooms',
  MISSING_SELLER_AVAILABILITIES = 'SellerHasNoAvailabalities',
  MISSING_SHOWERROOM = 'MissingShowers',
  MISSING_SURFACE = 'MissingSurface',
  MISSING_TITLE_OR_TEXT = 'MissingTitleOrText',
  MISSING_TOILETS = 'MissingToilets',
  MISSING_TYPE_BIEN = 'MissingTypeBien',
  MISSING_VALUATION_PRICE = 'MissingValuationPrice',
  UNWANTED_TEXT = 'UnwantedText',
  WRONG_TEXT_START = 'WrongTextStart'
}

export enum PublicationCheckWarningKind {
  INVALID_DPE_GES = 'MissingDPEGES',
  INVALID_PROPERTY_TAX = 'InvalidTax',
  MISSING_NONNULLROOM = 'MissingNonNullRoom',
  MISSING_PICTURE = 'MissingPicture'
}

export enum RenovationStatus {
  NEW = 'new',
  NOT_RENOVATED = 'not_renovated',
  PARTIALLY_RENOVATED = 'partially_renovated',
  RENOVATED = 'renovated'
}

export enum StateAtDeed {
  BARE_OWNERSHIP = 'bare_ownership',
  FREE = 'free',
  OCCUPIED = 'occupied',
  RENTED = 'rented'
}

export enum VirtualUpgrade {
  IN_PROGRESS = 'inprogress',
  DONE = 'done',
  REFUSED = 'refused',
  NOT_REQUESTED = 'notrequested'
}

export type TagStage = {
  color: TagColorsTypes;
  label: string;
};

export type ListingActivityCardProps = {
  agentViewing: nullOrNumber;
  city: string;
  estimation: Pick<OpportunityQualification, 'estimationStage' | 'id'>;
  id: number;
  pictures: ListingPictureProps[];
  postCode: number;
  price: nullOrNumber;
  street: string;
  surfaceArea: number;
  type: string;
  url: string;
  userId: string;
  roomsCount?: number;
  streetNumber?: string;
};

export type ListingPictureProps = {
  id: string;
  position: number;
  name: string;
  dir: string;
  attachment: string;
};

export type MandateInfo = {
  contractType: null | ContractType;
  mandateType: MandateType;
  exclusivityExpirationDate: string;
  validityDate: string;
  chargedTo?: MandateChargedToType;
};

export type ListingProps = {
  agentViewing: number;
  askingPrice?: nullOrNumber;
  allotmentNumber?: string;
  apartmentNumber?: string;
  balconySurfaceArea?: nullOrNumber;
  bathroomsCount?: number;
  bedroomsCount?: number;
  brightness?: string;
  buildingAllotmentsCount?: nullOrNumber;
  buildingApartmentsPerFloorCount?: nullOrNumber;
  buildingFloorsCount?: nullOrNumber;
  buildingNumber?: string;
  buyerChargedFees?: nullOrNumber;
  buyingDate?: string;
  buyingPrice?: nullOrNumber;
  caretakerDetails?: string;
  cellarAllotmentNumber?: string;
  cellarCount?: number;
  cellarNumber?: string;
  cellarProportionalShares?: string;
  cellarSurfaceArea?: nullOrNumber;
  cellarTotalProportionalShares?: string;
  chargedFees: nullOrNumber;
  city: string;
  constructionYear?: number;
  coolingType?: ListingCoolingType;
  coOwnersCount?: nullOrNumber;
  coOwnershipAllotmentsCount?: nullOrNumber;
  coOwnershipFees?: nullOrNumber;
  coOwnershipFeesDetails?: string;
  coOwnershipFeesRecurrence?: ListingFeesRecurrenceType;
  country: Country;
  createdAt: string;
  description?: string;
  deedSalesDate: nullOrString;
  digicodeDetails?: string;
  disableSellerNotifications: boolean;
  dpe?: number;
  dpeLetter: nullOrString;
  electricityFees?: nullOrNumber;
  electricityFeesRecurrence?: ListingFeesRecurrenceType;
  energyDiagnosticState?: ListingEnergyDiagnosticState;
  energyDiagnosticDate: nullOrString;
  energyRating?: ListingEnergyRating;
  estimation: EstimationProps;
  facilityRoomType?: string;
  finalSaleDate: nullOrString;
  finalSalePrice: nullOrNumber;
  firstPublication?: string;
  floor: nullOrNumber;
  floorDetails?: string;
  floorsCount?: nullOrNumber;
  frontRenovationYear?: number;
  frontType?: ListingFrontType;
  gardenSurfaceArea?: nullOrNumber;
  ges?: number;
  gesLetter: nullOrString;
  googleDriveId?: string;
  hasCaretaker?: boolean;
  hasCellar?: boolean;
  hasDigicode?: boolean;
  hasDoubleLivingRoom?: boolean;
  hasDrainColumns?: boolean;
  hasElevator?: boolean;
  hasEmptyKitchen?: boolean;
  hasFacilityRoom?: boolean;
  hasFurnishedKitchen?: boolean;
  hasIndependentKitchen?: boolean;
  hasIntercom?: boolean;
  hasLivingRoom?: boolean;
  hasLocker?: boolean;
  hasOngoingProceedings?: boolean;
  hasOpenKitchen?: boolean;
  hasParking?: boolean;
  hasParkingElevator?: boolean;
  hasParkingRamp?: boolean;
  hasPool: boolean;
  hasRenovatedFront?: boolean;
  hasSecondaryGlazing?: boolean;
  hasSecurityCameras?: boolean;
  hasUSIndependentKitchen?: boolean;
  hasUSKitchen?: boolean;
  heatingLocation?: ListingHeatingLocation;
  heatingMode?: ListingHeatingMode;
  heatingType?: ListingHeatingType;
  heatingUnderfloorType?: ListingHeatingUnderfloorType;
  height?: string;
  id: number;
  intercomDetails?: string;
  interest?: nullOrBoolean;
  isCurrentlyRented?: boolean;
  isFurnished?: boolean;
  isOnSale?: boolean;
  isParkingJoint?: boolean;
  isRestorationWorkPaid?: boolean;
  latestRestorationWorkDetails?: string;
  latitude: number;
  livingRoomOrientationEast?: boolean;
  livingRoomOrientationNorth?: boolean;
  livingRoomOrientationNorthEast?: boolean;
  livingRoomOrientationNorthWest?: boolean;
  livingRoomOrientationSouth?: boolean;
  livingRoomOrientationSouthEast?: boolean;
  livingRoomOrientationSouthWest?: boolean;
  livingRoomOrientationWest?: boolean;
  livingRoomViewType?: ListingLivingRoomViewType;
  livingSurfaceArea?: string;
  localTax?: nullOrNumber;
  locationFromIris: LocationState;
  locationFromNeighborhood: LocationState;
  locationFromMunicipality: LocationState;
  locationFromSubmunicipality: LocationState;
  lockerAllotmentNumber?: string;
  lockerNumber?: string;
  lockerProportionalShares?: string;
  lockerTotalProportionalShares?: string;
  longitude: number;
  mandateInfo: MandateInfo | null;
  meetingPlace?: string;
  modifiedAt: string;
  onSaleType?: ListingOnSaleType;
  orientationEast?: boolean;
  orientationNorth?: boolean;
  orientationNorthEast?: boolean;
  orientationNorthWest?: boolean;
  orientationSouth?: boolean;
  orientationSouthEast?: boolean;
  orientationSouthWest?: boolean;
  orientationWest?: boolean;
  parkingAllotmentNumber?: string;
  parkingCount?: number;
  parkingHeight?: string;
  parkingJointType?: ListingParkingJointType;
  parkingLength?: string;
  parkingNumber?: string;
  parkingProportionalShares?: string;
  parkingTotalProportionalShares?: string;
  parkingType?: ListingParkingType;
  parkingWidth?: string;
  pictures: ListingPictureProps[];
  poolArea?: nullOrNumber;
  price: nullOrNumber;
  propertyTax?: nullOrNumber;
  postCode: string;
  proportionalShares?: string;
  quality?: ListingQuality;
  renovationStatus?: RenovationStatus;
  renovationYear?: number;
  rentalEndDate?: string;
  rentalDetails?: string;
  rentalPrice?: nullOrNumber;
  restorationWorkDone?: string;
  restorationWorkNeeded?: string;
  roomsCount?: number;
  sanifloCount?: number;
  separateToiletsCount?: number;
  showcase: boolean;
  showerRoomsCount?: number;
  stateAtDeed?: StateAtDeed;
  street: string;
  streetNumber?: string;
  suggestedEstimationPrice?: nullOrNumber;
  suggestedEstimationPriceLowerRange?: nullOrNumber;
  suggestedEstimationPriceUpperRange?: nullOrNumber;
  surfaceArea: number;
  status: ListingStatus;
  skipPreliminary: boolean;
  teamLeadRecommendedPrice?: nullOrNumber;
  terraceSurfaceArea?: nullOrNumber;
  title?: string;
  toiletsCount?: number;
  totalProportionalShares?: string;
  type: PropertyEnum;
  updatedAt: string;
  url: string;
  userId: string;
  uuid: string;
  valide: ListingStatus;
  valuationListingPriceCheapest?: number;
  valuationListingPriceExpensive?: number;
  valuationListingPriceIdeal?: number;
  viewingDurationMinutes: number;
  virtualVisit: string;
  votedRestorationWorkDetails?: string;
  windowType?: ListingWindowType;
};

export type ListingHistoryProps = {
  effective_start_date: string;
  prix: nullOrNumber;
};

export type EstimationProps = {
  agentId: number;
  archiveStages: EstimationStage[];
  estimationStage: EstimationStage;
  city: string;
  id: number;
  leadSource: LeadSource;
  listingId: number;
  salesforceId: string;
  stage: string;
  street: string;
  postCode: string;
  documents?: DocumentProps[];
  streetNumber?: string;
  virtualUpgrade?: VirtualUpgrade;
  visibleByAgent?: string;
  reasonForSelling?: string;
  ownershipSource?: SellerOwnership;
  askingPrice?: nullOrNumber;
};

export type InnerDistributionProps = {
  activated: boolean;
  platform: ListingDistribution;
};
export type DistributionProps = {
  distribution: InnerDistributionProps;
  label: string;
};

export type ListingDistributionsProps = {
  distributions: DistributionProps[];
  firstPublicationDate: nullOrString;
  useAgentNumber: boolean;
  delay?: number;
};

export type LightshopCredentials = {
  accessToken: string;
  refreshToken: string;
};

export type LightshopOrderProps = {
  id: number;
  status: LightshopOrderStatus;
  meetingDate?: string;
  productId?: number;
};

export type PublicationCheckError = {
  errorMessage: string;
  errorType: PublicationCheckErrorKind;
};

export type PriceAndFee = {
  fee: nullOrNumber;
  netPrice: nullOrNumber;
  percentageFee: nullOrNumber;
  price: nullOrNumber;
};

export type TooltipDistributionLabelProps = {
  translationArguments: ArbitraryObject;
  translationKey: string;
};
