import { LeadSource } from '@proprioo/hokkaido';

export const getLeadsourceLabel = (leadsource: LeadSource) => {
  switch (leadsource) {
    case LeadSource.BUSINESS_INTRODUCER:
      return 'businessIntroducer';
    case LeadSource.BUYER_SELLER:
      return 'buyerSeller';
    case LeadSource.CASAVO_DELEGATION:
      return 'casavoDelegation';
    case LeadSource.CASAVO_LEAD:
      return 'casavo';
    case LeadSource.CASAVO_PROSPECT:
      return 'sellerSuccess';
    case LeadSource.CASAVO_SMP_IBUYER_MANDATE:
      return 'casavoIBuyer';
    case LeadSource.CASAVO_SMP_MANDATE:
      return 'casavoMandate';
    case LeadSource.CASAVO_VALUATION:
      return 'casavoOnlyValuation';
    case LeadSource.CHALLENGE_PICKING:
      return 'challengePige';
    case LeadSource.COMMERCIAL_OPERATION:
      return 'commercialOperation';
    case LeadSource.CREDIT_AGRICOLE:
      return 'creditAgricoleIDF';
    case LeadSource.DELEGATION:
      return 'delegation';
    case LeadSource.DOOR_TO_DOOR:
      return 'doorToDoor';
    case LeadSource.FIND_NEST:
      return 'chercheMonNid';
    case LeadSource.FLYER:
      return 'flyer';
    case LeadSource.FORMER_CLIENT:
      return 'formerClient';
    case LeadSource.MARKETING_EVENT:
      return 'marketingEvent';
    case LeadSource.NETBUREAU:
      return 'netbureau';
    case LeadSource.ONLINE_VALUATION:
      return 'onlineValuation';
    case LeadSource.PERSONAL_NETWORK:
      return 'personalNetwork';
    case LeadSource.PHONE_BOOK:
      return 'phoneBook';
    case LeadSource.PHONE_PROSPECTING:
      return 'phoneProspecting';
    case LeadSource.PHONE_PROSPECTING_IS:
      return 'phoneProspectingInsideSales';
    case LeadSource.PICKING:
      return 'picking';
    case LeadSource.PROPERTY_DEALER:
      return 'propertyDealer';
    case LeadSource.REFERRAL:
      return 'referral';
    case LeadSource.SOCIAL_NETWORK:
      return 'socialNetwork';
    case LeadSource.STANDARD_PHONE_CALL:
      return 'standardPhoneCall';
    case LeadSource.TREE_DIGITAL_FACTORY:
      return 'treeDigitalFactory';
    case LeadSource.VALUATION_MEETING:
      return 'valuationMeeting';
    case LeadSource.YANPORT:
      return 'yanport';
    case LeadSource.INDUSTRIAL:
      return 'industrial';
    case LeadSource.PARTNERSHIP_AI_FA:
      return 'partnershipAiFa';
    case LeadSource.PARTNERSHIP_IMMOBILIARE_VALUTA_NO:
      return 'partnershipImmobiliareValutaNo';
  }
};
