import Flat from '@/assets/icons/flat.svg';
import House from '@/assets/icons/house.svg';
import { isCasavoLeadSource } from '@/components/app/leadTag/utils';
import { isProspectionSource } from '@/components/myCustomers/mySellers/LeadView.utils';
import { AuthUserProps, UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';
import { getLeadsourceLabel } from '@/hooks/useFormatProjectFromOpportunity/utils';
import {
  Country,
  LeadSource,
  listToString,
  nullOrString,
  OptionProps,
  PropertyEnum
} from '@proprioo/hokkaido';

import {
  OpportunityOrigin,
  Options,
  treatmentPriority
} from '../sellerQualification/interfaces';
import { PartialAgent, SourceOptionProps } from './ProjectForm.interfaces';

export const isNumberNullOrBiggerThanZero = (value?: nullOrString) =>
  value ? !isNaN(parseInt(value, 10)) && parseInt(value, 10) > 0 : true;

export const getOptionPropsFromAgent = ({
  firstname,
  lastname,
  id,
  isDuplicated,
  squads
}: PartialAgent): OptionProps => {
  const squadNames = isDuplicated
    ? squads?.map(({ name }) => `${name}`).join(', ')
    : undefined;

  return {
    label: listToString([firstname, lastname, squadNames && `(${squadNames})`]),
    value: `${id}`
  };
};

export const getNullableOptionPropsFromAgent = (
  agent?: PartialAgent
): OptionProps | null => (agent ? getOptionPropsFromAgent(agent) : null);

export const isInternalOrigin = (origin: OpportunityOrigin) =>
  origin === OpportunityOrigin.BACKOFFICE ||
  origin === OpportunityOrigin.PLANNER ||
  origin === OpportunityOrigin.SALES;

export const isLeadOrigin = (origin: OpportunityOrigin) =>
  origin === OpportunityOrigin.BOOKING ||
  origin === OpportunityOrigin.VALUATION;

export const treatmentPriorityOptions: OptionProps[] = [
  {
    label: 'lessThanThreeMonths',
    value: treatmentPriority.IMMEDIATE
  },
  {
    label: 'betweenThreeAndSixMonths',
    value: treatmentPriority.COMING_MONTHS
  },
  {
    label: 'betweenSixAndTwelveMonths',
    value: treatmentPriority.POSSIBLE_PROJECT
  },
  {
    label: 'moreThanTwelveMonths',
    value: treatmentPriority.LOWEST
  },
  {
    label: 'notInterestSelling',
    value: treatmentPriority.NOT_INTERESTED_SELLING
  }
];

export const propertiesOptions: Options<PropertyEnum>[] = [
  {
    image: <Flat />,
    text: 'flat',
    value: PropertyEnum.FLAT
  },
  {
    image: <House />,
    text: 'house',
    value: PropertyEnum.HOUSE
  }
];

export const allLeadOptions: SourceOptionProps[] = Object.values(
  LeadSource
).map(source => ({ label: getLeadsourceLabel(source), value: source }));

export const leadOptionsFiltered = allLeadOptions.filter(
  ({ value }) =>
    ![
      LeadSource.CASAVO_VALUATION,
      LeadSource.CASAVO_LEAD,
      LeadSource.CASAVO_SMP_IBUYER_MANDATE,
      LeadSource.CASAVO_SMP_MANDATE,
      LeadSource.ONLINE_VALUATION,
      LeadSource.VALUATION_MEETING,
      LeadSource.YANPORT
    ].includes(value)
);

export const leadOptionsSellerSuccess = allLeadOptions.filter(({ value }) =>
  [
    LeadSource.CASAVO_PROSPECT,
    LeadSource.NETBUREAU,
    LeadSource.INDUSTRIAL,
    LeadSource.PARTNERSHIP_AI_FA,
    LeadSource.PARTNERSHIP_IMMOBILIARE_VALUTA_NO
  ].includes(value)
);

export const leadOptionsIrecMopsPremiumPartner = allLeadOptions.filter(
  ({ value }) =>
    [LeadSource.BUYER_SELLER, LeadSource.PERSONAL_NETWORK].includes(value)
);

export const leadOptionsAgent = leadOptionsFiltered.filter(
  ({ value }) =>
    ![
      LeadSource.CASAVO_DELEGATION,
      LeadSource.CASAVO_PROSPECT,
      LeadSource.CREDIT_AGRICOLE,
      LeadSource.NETBUREAU,
      LeadSource.PHONE_PROSPECTING_IS,
      LeadSource.STANDARD_PHONE_CALL,
      LeadSource.TREE_DIGITAL_FACTORY,
      LeadSource.INDUSTRIAL,
      LeadSource.PARTNERSHIP_AI_FA,
      LeadSource.PARTNERSHIP_IMMOBILIARE_VALUTA_NO
    ].includes(value)
);

export const getLeadOptions = (
  user: AuthUserProps | undefined
): SourceOptionProps[] => {
  if (hasRole(UserRole.APP_SALES_ADMIN, user) || hasRole(UserRole.TECH, user)) {
    return leadOptionsFiltered;
  }

  if (hasRole(UserRole.SELLER_SUCCESS, user)) {
    return leadOptionsSellerSuccess;
  }

  if (
    hasRole(UserRole.IREC, user) ||
    hasRole(UserRole.MOPS, user) ||
    hasRole(UserRole.PREMIUM_PARTNER, user)
  ) {
    return leadOptionsIrecMopsPremiumPartner;
  }

  return leadOptionsAgent;
};

export const isMarketingLead = (leadSource: LeadSource): boolean =>
  !isProspectionSource(leadSource) || isCasavoLeadSource(leadSource);

export const convertShortNameIntoCountry = (shortName: string): Country => {
  switch (shortName) {
    case 'IT':
      return Country.ITALY;
    case 'ES':
      return Country.SPAIN;
    case 'FR':
      return Country.FRANCE;
    default:
      return Country.FRANCE;
  }
};

export const getCountryFromAddress = (
  googleAddressComponent: google.maps.GeocoderAddressComponent[]
): Country => {
  if (!googleAddressComponent.length) return Country.FRANCE;

  const shortName = googleAddressComponent.filter(component =>
    component.types.includes('country')
  )[0].short_name;

  return convertShortNameIntoCountry(shortName);
};
